import {isFuture} from 'date-fns'

export const useUserStore = defineStore('user', {
  state: () => {
    return { 
      user: null, 
      session: null,
      role: null,
      memberShip: null,
    }
  },
  
  actions: {
    setUser(user) {
      this.user = user
    },
    setRole(role) {
      this.role = role
    },
    setSession(session) {
      this.session = session
    },
    setMemberShip(memberShip) {
      this.memberShip = memberShip
    },
  },
  getters: {
    isThaiPassMember(state){
      return state.memberShip?.status === 'SUCCESS' && isFuture(state.memberShip?.expiry_date)
    }
  },
  persist: true,
})